<template>
  <div>
    <div>
      <div class="row py-24 px-12 oonpay-bg">
        <div class="col-md-12">
          <h4 class="font-semibold text-center text-oonpaytext text-3xl">
            Package Specification
          </h4>
        </div>
      </div>

      <auth-modal v-if="!this.islogin" />

      <div class="row py-5">
        <div class="container">
          <div class="row d-flex justify-content-center">
            <div class="col-md-6">
              <div class="form-group">
                <label for="">Subcategory</label>
                <select
                  @change="handleSubcategory"
                  name=""
                  class="form-control"
                  
                >
                  <option
                    v-for="item in subcategories"
                    :key="item.id"
                    :value="item.id"
                    :selected="item.id == id"
                    >{{ item.fullName }}</option
                  >
                </select>
              </div>

              <purchase-pack-for-others :all_countries="all_countries" />

              <div class="form-group">
                <h6 for="purchase for">Package Location</h6>
              </div>

              <googleSearch />

              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import googleSearch from "../../components/googleSearch";
import AuthModal from "../Global/AuthModal.vue";
import PurchasePackForOthers from "../Global/purchasePackForOthers.vue";

export default {
  data() {
    return {
      islogin: localStorage.getItem("login_status"),
      id: this.$route.params.id,
    };
  },
  components: {
    googleSearch,
    PurchasePackForOthers,
    AuthModal
  },
  computed: {
    ...mapGetters("foodhub", ["supercategories", "subcategories","subcategoryid"]),
    ...mapGetters("dashboard", ["all_countries"])
  },
  mounted() {
    // const categoryId = this.subcategoryid || localStorage.getItem('categoryId')

    localStorage.setItem("BeneficiaryTypeId", 3);
    this.$store.dispatch("foodhub/getFoodHubSuperCategories");
   this.$store.dispatch("foodhub/getFoodHubSuberCategories",localStorage.getItem("paramsId"));
    this.$store.dispatch("dashboard/GetAllCountries");

    if (!this.islogin) {
      const modal = document.getElementsByClassName("modal");
      modal[0].style.visibility = "visible";
      modal[0].style.opacity = 1;
    }
  },

  methods: {
    handleSubcategory(e) {
      this.$store.dispatch("foodhub/getFoodHubSuberCategories", this.subcategoryid);
    }
  }
};
</script>
